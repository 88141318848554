import { Link } from '@remix-run/react';
import { useSearchParams } from '@remix-run/react';
import { useCallback, useMemo } from 'react';

import { OrganizationSelect } from '../components/Organization';
import {
  SESSION_MODE_OPTIONS,
  SessionModeFilter,
  SessionTrackHistory,
} from '../components/Session';

function SessionFilterControl(): JSX.Element {
  const { filters, setFilters } = useSessionFilters();
  const sessionMode = useMemo(() => {
    return (
      SESSION_MODE_OPTIONS.find((o) => o.key === filters.mode)?.value ?? {
        mode: null,
        onDemandControllerKind: null,
      }
    );
  }, [filters.mode]);

  return (
    <div>
      <div className='flex items-center gap-4'>
        <div className='w-50'>
          <OrganizationSelect
            className='w-full'
            orgId={filters.orgId ?? null}
            onChange={(org) =>
              setFilters({
                ...filters,
                orgId: org?.id,
              })
            }
            isClearable
          />
        </div>
        <SessionModeFilter
          value={sessionMode}
          onChange={(_, key) => {
            setFilters({
              ...filters,
              mode: key,
            });
          }}
        />
        <div className='flex flex-row items-center'>
          <input
            type='checkbox'
            className='checkbox-dark'
            checked={!(filters.invalid ?? false)}
            onChange={(e) =>
              setFilters({
                ...filters,
                invalid: !e.target.checked ? true : undefined,
              })
            }
          />
          <p className='ml-2 text-sms'>Valid Session Only</p>
        </div>
      </div>
    </div>
  );
}

type SessionFilters = {
  orgId?: string;
  mode?: string;
  invalid?: boolean;
};

function useSessionFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useMemo(() => {
    const filters: SessionFilters = {};
    if (searchParams.has('orgId')) {
      filters.orgId = searchParams.get('orgId') ?? undefined;
    }
    if (searchParams.has('mode')) {
      filters.mode = searchParams.get('mode') ?? undefined;
    }
    if (searchParams.has('invalid')) {
      filters.invalid = searchParams.get('invalid') === 'true';
    }
    return filters;
  }, [searchParams]);

  const setFilters = useCallback(
    (filters: SessionFilters) => {
      setSearchParams((prev) => {
        for (const [filterName, value] of Object.entries(filters)) {
          if (value === undefined) {
            prev.delete(filterName);
          } else {
            prev.set(filterName, String(value));
          }
        }
        return prev;
      });
    },
    [setSearchParams]
  );

  const clearFilters = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('orgId');
      prev.delete('mode');
      prev.delete('invalid');
      return prev;
    });
  }, [setSearchParams]);

  return {
    filters,
    setFilters,
    clearFilters,
  };
}

function SessionsRoute() {
  const { filters } = useSessionFilters();
  const searchParams = useMemo(() => {
    const sessionMode = SESSION_MODE_OPTIONS.find((o) => o.key === filters.mode)
      ?.value ?? {
      mode: null,
      onDemandControllerKind: null,
    };
    return {
      orgId: filters.orgId,
      ...sessionMode,
    };
  }, [filters.mode, filters.orgId]);

  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <div className='mb-8 flex items-center justify-between'>
        <SessionFilterControl />
        <Link to='/admin/toolkit/sessions/inspector'>
          Game Session Inspector
        </Link>
      </div>
      <SessionTrackHistory
        searchParams={searchParams}
        config={{
          organizer: true,
          mode: true,
          org: true,
          actionSheet: {
            showEdit: true,
            showCopyStreamId: true,
            showInspect: true,
          },
          controller: true,
          adminLinks: true,
          localTime: true,
        }}
        useDefaultFilters={!filters.invalid}
      />
    </div>
  );
}

export const Component = SessionsRoute;

export function clientLoader() {
  return null;
}
